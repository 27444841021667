<template>
  <div>
    <template v-for="(option, i) in exercise.body">
      <v-row class="ml-12" align="center" :key="i">
        <h4 class="mt-1 mr-4">
          <template v-if="type_expand === 0">
            {{ i + section.offset_question + exercise.offset_question + 1 }}.
          </template>
          <template v-if="type_expand === 1"> {{ i + 1 }}. </template>
        </h4>
        <h5 style="color: #6495ed" class="mt-1 mr-2">
          {{ i | getAlphabetCharacterFromIndex }}.
        </h5>
        <v-checkbox
          readonly
          v-model="option.checked"
          :label="option.value"
        ></v-checkbox>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListSectionType",
  props: ["section", "exercise", "type_expand"],
};
</script>

<style></style>
